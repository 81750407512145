import "core-js";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AppConfig } from './utils/Config/AppConfig';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";


const themes = {
  dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/light-theme.css`,
};
console.log('AppConfig.BASENAME',AppConfig.BASENAME)
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={`/${AppConfig.BASENAME}`}>
      <ThemeSwitcherProvider themeMap={themes} defaultTheme="light">
        <App />
      </ThemeSwitcherProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
