export const ApiRoutes = {
  LISTTIMEZONE: {
    service: "/web/v1/dashboard",
    url: "/timezone",
    method: "GET",
    authenticate: true,
  },
  ADDTIMEZONE: {
    service: "/web/v1/dashboard",
    url: "/shiftHours",
    method: "POST",
    authenticate: true,
  },
  GETALLFILTER: {
    service: "/web/v1/dashboard",
    url: "/getfilter",
    method: "GET",
    authenticate: true,
  },
  GETEMPLOYEELIST: {
    service: "/web/v1/dashboard",
    url: "/employeeList",
    method: "GET",
    authenticate: true,
  },
  GETALLRECORD: {
    service: "/web/v1/dashboard",
    url: "/records",
    method: "GET",
    authenticate: true,
  },
  GETALLNONREVRECORD: {
    service: "/web/v1/dashboard",
    url: "/non-revenue-records",
    method: "GET",
    authenticate: true,
  },
  GETPAYMENTRECORD: {
    service: "/web/v1/dashboard",
    url: "/get-payment-record",
    method: "GET",
    authenticate: true,
  },

  GETPAYMENTRECORDREVENUE: {
    service: "/web/v1/dashboard",
    url: "/get-payment-record-revenue",
    method: "GET",
    authenticate: true,
  },
  SALEEXPORTEXCEl: {
    service: "/web/v1/dashboard",
    url: "/exportexcel",
    method: "GET",
    authenticate: true,
  },
  ITEMSALEPRODUCT: {
    service: "/web/v1/dashboard",
    url: "/items-sales",
    method: "GET",
    authenticate: true,
  },
  NONREVENUEPRODUCT: {
    service: "/web/v1/dashboard",
    url: "/non-revenue-sales",
    method: "GET",
    authenticate: true,
  },
  SUMMARY_REPORT_LIST: {
    service: "/web/v1/dashboard",
    url: "/summary-report",
    method: "GET",
    authenticate: true,
  },
  NON_REV_SUMMARY_REPORT_LIST: {
    service: "/web/v1/dashboard",
    url: "/non-revenue-summary-report",
    method: "GET",
    authenticate: true,
  },
  GIFT_CARD_COUNT: {
    service: "/web/v1/dashboard",
    url: "/giftcard-count",
    method: "GET",
    authenticate: true,
  },
  GIFT_CARD_DETAILS: {
    service: "/web/v1/dashboard",
    url: "/giftcard-details",
    method: "GET",
    authenticate: true,
  },
  GIFT_CARD_EXPORT: {
    service: "/web/v1/dashboard",
    url: "/giftcard-export",
    method: "GET",
    authenticate: true,
  },
  VERIFY_TOKEN: {
    service: "/web/v1/user",
    url: "/verify-token",
    method: "POST",
    authenticate: false,
  },
  ITEMEXPORTEXCEl: {
    service: "/web/v1/dashboard",
    url: "/itemexportexcel",
    method: "GET",
    authenticate: true,
  },
  DELETE_TIMEZONE: {
    service: "/web/v1/dashboard",
    url: "/shift-hours/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_WALLET_BAL: {
    service: "/web/v1/digipoint",
    url: "/wallet",
    method: "POST",
    authenticate: true,
  },
  WALLET_HISTORY: {
    service: "/web/v1/digipoint",
    url: "/wallet-history",
    method: "POST",
    authenticate: true,
  },
  PRODUCT_INVENTORY: {
    service: "/store/product",
    url: "/invetory-list",
    method: "GET",
    authenticate: true,
  },
  PRODUCT_INVENTORY_DETAILS: {
    service: "/store/product",
    url: "/invetory-list/:id",
    method: "GET",
    authenticate: true,
  },
  VENDOR_LIST: {
    service: "/store/product",
    url: "/vendor-list",
    method: "GET",
    authenticate: true,
  },
  EXPORT_PRODUCT: {
    service: "/store/product",
    url: "/export-product",
    method: "GET",
    authenticate: true,
  },
  PRODUCT_CATEGORY: {
    service: "/store/product",
    url: "/category-list",
    method: "GET",
    authenticate: true,
  },
  PRODUCT_SUBCATEGORY: {
    service: "/store/product",
    url: "/sub-category-list",
    method: "GET",
    authenticate: true,
  },
  UPDATE_INVENTORY: {
    service: "/store/product",
    url: "/update-inventory",
    method: "POST",
    authenticate: true,
  },
  TIME_CLOCK_LIST: {
    service: "/store/time-clock",
    url: "",
    method: "GET",
    authenticate: true,
  },
  EXPORT_TIME_CLOCK: {
    service: "/store/time-clock",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  TIME_CLOCK_EMPLOYEE_LIST: {
    service: "/store/time-clock",
    url: "/employee",
    method: "GET",
    authenticate: true,
  },
  TIME_CLOCK_EDIT:{
    service: "/store/time-clock",
    url: "/edit-time",
    method: "PUT",
    authenticate: true,
  },
  PAYROLL_LIST:{
    service: "/store/time-clock",
    url: "/payroll",
    method: "GET",
    authenticate: true,
  },
  PAYROLL_EXPORT:{
    service: "/store/time-clock",
    url: "/payroll-export",
    method: "GET",
    authenticate: true,
  },
  PAYROLL_EMAIL_STATEMENT:{
    service: "/store/time-clock",
    url: "/payroll-email-statement",
    method: "GET",
    authenticate: true,
  },
  LIST_INFO: {
    service: "/retailer/sell",
    url: "/getLiteInfo",
    method: "GET",
    authenticate: false,
  },
  CREATE_ORDER: {
    service: "/retailer/payment",
    url: "/createLiteOrderFun",
    method: "POST",
    authenticate: false,
  },
  RETURN_INVOICE: {
    service: "/retailer/returnProduct",
    url: "/productSearch",
    method: "POST",
    authenticate: false,
  },
  STAFFS : {
    service : "/support",
    url : "/staffs"
  },
  STAFF:{
    service : "/support",
    url : "/staff"
  },
  ADMIN_STAFF:{
    service:"/support",
    url:'/admin-staff'
  },
  ADMIN_STAFF_ROLELIST:{
    service:"/support",
    url:'/admin-staff-roleList'
  },
  STAFF_DESIGNATION:{
    service:"/support",
    url:"/staff/designation"
  },
  STAFF_REVEAL_PIN:{
    service:"/support",
    url:"/staff/reveal-pin"
  },
  STAFF_TOGGLE:{
    service:"/support",
    url:"/staff/toggle"
  },
  MERCHANTS:{
    service : "/support",
    url : "/merchant"
  },
  MERCHANT_STORE:{
    service:'/support',
    url:'/merchant/store'
  },
  MERCHANT_STORE_LIST:{
    service:'/support',
    url:'/merchant-store'
  },
  MERCHANT_TOGGLE:{
    service:'/support',
    url:'/merchant/toggle'
  },
  MERCHANT_PARAMETERS:{
    service:'/support',
    url:'/merchant-parameters'
  },
  MERCHANT_PARAMETER_TOGGLE:{
    service:'/support',
    url:'/merchant/toggle-parameter'
  },
  MERCHANT_ORDERS:{
    service:'/support',
    url:'/orders/merchantOrder'
  },
  ORDERS:{
    service:'/support',
    url:'/orders'
  },
  ORDER_STATUS:{
    service:'/support',
    url:'/order-status'
  },
  STORE_RECEIPTS:{
    service:'/support',
    url:'/store/receipts'
  },
  STORE_STAFFS:{
    service:'/support',
    url:'/store/staffs'
  },
  STORE_RECEIPT:{
    service:'/support',
    url:'/store/receipt'
  },
  STORE_NOTES:{
    service:"/support",
    url:"/store/store-notes"
  },
  STORE_TOGGLE:{
    service:'/support',
    url:'/store/toggleStore'
  },
  STORE_TERMINAL:{
    service:'/support',
    url:'/store/terminal'
  },
  SUPPORT_VERIFY_TOKEN:{
    service:'/support',
    url:'/verify-token'
  },
  COUNTRY:{
    service:"/common",
    url:"/country"
  },
  STATE:{
    service:"/common",
    url:"/state"
  },
  CITY:{
    service:"/common",
    url:"/city"
  },
  GET_DISTRIBUTOR: {
    service: "/distributor",
    url: "/"
  },
  TOGGLE_DISTRIBUTOR: {
    service: "/distributor",
    url: "/toggle/"
  },
  UPDATE_DISTRIBUTOR: {
    service: "/distributor",
    url: "/"
  },
  DISTRIBUTOR_DETAIL: {
    service: "/distributor",
    url: "/"
  },
  DISTRIBUTOR_MENU: {
    service: "/distributor",
    url: "/menu"
  },
  ADD_DISTRIBUTOR: {
    service: "/distributor",
    url: "/"
  },
  GET_CAMPAIGN: {
    service: "/distributor",
    url: "/campaign"
  },
  TOGGLE_CAMPAIGN: {
    service: "/distributor",
    url: "/campaign/toggle/"
  },
  UPDATE_CAMPAIGN: {
    service: "/distributor",
    url: "/campaign/"
  },
  CAMPAIGN_DETAIL: {
    service: "/distributor",
    url: "/campaign/"
  },
  ADD_CAMPAIGN: {
    service: "/distributor",
    url: "/campaign"
  },
  GET_AGENT: {
    service: "/distributor",
    url: "/agent"
  },
  TOGGLE_AGENT: {
    service: "/distributor",
    url: "/agent/toggle/"
  },
  UPDATE_AGENT: {
    service: "/distributor",
    url: "/agent/"
  },
  AGENT_DETAIL: {
    service: "/distributor",
    url: "/agent/"
  },
  ADD_AGENT: {
    service: "/distributor",
    url: "/agent"
  },
  BULK_AGENT_VERIFY: {
    service: "/distributor",
    url: "/agent/bulk-verify"
  },
  BULK_AGENT_UPLOAD: {
    service: "/distributor",
    url: "/agent/bulk-upload"
  },
  GET_LEAD: {
    service: "/distributor",
    url: "/lead"
  },
  TOGGLE_LEAD: {
    service: "/distributor",
    url: "/lead/toggle/"
  },
  LEAD_DETAIL: {
    service: "/distributor",
    url: "/lead/"
  },
  GET_MERCHANT: {
    service: "/distributor",
    url: "/merchant"
  },
  MERCHANT_DETAIL: {
    service: "/distributor",
    url: "/merchant/"
  },
  NONREVENUEEXPORTEXCEl: {
    service: "/web/v1/dashboard",
    url: "/non-revenue-exportexcel",
    method: "GET",
    authenticate: true,
  },
};
