import React, { useEffect, useState } from "react";
import HorizontalMenu from "../../../layout/Horizontalmenu.js";
import Filter from "../../../common/filter";
import Selectitemsrank from "../../../layout/filter/Itemsrank";
import Selectitemssummary from "../../../layout/filter/Itemssummary";
import {
  ApiHelper,
  getUserData,
  getToken
} from "../../../../utils/Helpers";
import Emptymsg from "../../../layout/message/Emptymsg";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes";
import { AppConfig } from "../../../../utils/Config/AppConfig.js";
import { messages } from "../../../../utils/Common/Messages";
import { Layout, Row, Col, Skeleton, message, Table,Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import GiftCardCount from "./GiftCardCount.js";

const { Content } = Layout;


const GiftCards = (props) => {
    const userData = getUserData();
    const lan = userData.language ?? "EN";
    const organizationId = userData.organizationTypeId===1?userData.parentOrganizationId:userData.organizationId
    const [noDatAvailable, setNoDatAvailable] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [total,setTotal] = useState(0)
    
    const columns = [
      {
        title: messages.cardNumber[lan],
        dataIndex: "cardNumber",
        key: "cardNumber",
        render: (cardNumber) => {
          return cardNumber;
        }
      },
      {
        title: messages.TransactionAmount[lan],
        dataIndex: "TransactionAmount",
        key: "TransactionAmount",
        render: (TransactionAmount) => {
          return TransactionAmount;
        }
      },
      {
        title: messages.Currency[lan],
        dataIndex: "Currency",
        key: "Currency",
        render: (Currency) => {
          return Currency;
        }
      },
      {
        title: messages.cardTypeDescription[lan],
        dataIndex: "cardTypeDescription",
        key: "cardTypeDescription",
        render: (cardTypeDescription) => {
          return cardTypeDescription;
        }
      },
      {
        title: messages.transactionDate[lan],
        dataIndex: "transactionDate",
        key: "transactionDate",
        render: (transactionDate) => {
          return transactionDate
        }
      }
    ]
    
    const getCardDetails = async()=>{
        try {
          setLoadings(true)
          setNoDatAvailable(true)
          let response = await new ApiHelper().FetchFromServer(
            ApiRoutes.GIFT_CARD_DETAILS.service,
            ApiRoutes.GIFT_CARD_DETAILS.url,
            ApiRoutes.GIFT_CARD_DETAILS.method,
            ApiRoutes.GIFT_CARD_DETAILS.authenticate,
            {
              organizationId:organizationId*55,
              pageSize,
              pageIndex
            }
          )
          if(response.code===200)
          {
            if(response.data.totalRows!==0)
            {
              setNoDatAvailable(false)
              setLoadings(false)
              setData(response.data.cardList)
              setTotal(response.data.totalRows)
            }
            else
            {
              setLoadings(false)
              setNoDatAvailable(true)
            }
            
          }
          else
          {
            message.error({
              content:"Internal Server Error",
              duration:2
            })
          }
        } catch (error) {
            message.error({
              content:error.message || "Error Occoured!",
              duration:2
            })
        }
    }

    const exportData = async ()=>{
    let token = getToken()
    window.open(
      `${AppConfig.API_ENDPOINT}${ApiRoutes.GIFT_CARD_EXPORT.service}${ApiRoutes.GIFT_CARD_EXPORT.url}?organizationId=${organizationId*55}&token=${token}`,
      "_blank"
    );
    }
    const nextData = (pagination) => {
      setLoading(true)
      setPageSize(pagination.pageSize);
      setPageIndex(pagination.current);
      getCardDetails();
      setLoading(false)
    };

    useEffect(()=>{
      getCardDetails()
    },[pageIndex,pageSize])

    return <>
        <HorizontalMenu />
        {
            !noDatAvailable?<>
            <Row>
              <Col className="col-11">
                <GiftCardCount/>
              </Col>
              <Col className="col-1">
              <Button type="primary" icon={<DownloadOutlined />} className="cursor-pointer" onClick={exportData}>
                {messages.Export[lan]}
              </Button>
              </Col>
            </Row>
            
            <Content className="content-body" style={{ minHeight: 200 }}>
            <Row>
                <Col lg={24} xs={24}>
                  <div
                    className="bg-white"
                    style={{
                      margin: "14px 14px 0px 14px",
                      padding: 14,
                      minHeight: 200
                    }}
                  >
                  <Table
                  columns={columns}
                  dataSource={data}
                  className="walletTable"
                  pageIndex={pageIndex}
                  scroll={{ x: 'max-content' }}
                  pagination={
                    {
                      pageSize: pageSize,
                      total: total,
                      defaultCurrent: pageIndex,
                      pageSizeOptions: ['10', '20', '50'],
                      showSizeChanger: true,
                      locale: { items_per_page: `/ ${messages.page[lan]}` }
                    }
                  }
                  locale={{
                    emptyText: loading ? (
                      <Skeleton
                        paragraph={{ rows: 5 }}
                        loading={loading}
                        active
                      ></Skeleton>
                    ) : (
                      <Emptymsg
                        emptytitle={messages.emptyTitleProductInventory[lan]}
                      />
                    )
                  }}
                  onChange={nextData}
                  />
                  </div>
                </Col>
            </Row>
            </Content>
            
            </>:(
                <Content className="content-body" style={{ minHeight: 200 }}>
                  <Row>
                    <Col lg={24} xs={24}>
                      <div
                        className="bg-white"
                        style={{
                          margin: "0px 14px 0px 14px",
                          padding: 14,
                          minHeight: 200,
                        }}
                      >
                        <Skeleton paragraph={{ rows: 8 }} loading={loadings} active>
                          <Emptymsg
                            emptytitle={messages.emptyTitle[lan]}
                            emptydescription={messages.emptyDescription[lan]}
                          />
                        </Skeleton>
                      </div>
                    </Col>
                  </Row>
                </Content>
              )
        }
    </>
};
export default GiftCards;
