import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/charts";
import { Column } from "@ant-design/charts";
//import Pagetitle from "../../../layout/Pagetitle.js";
// import HorizontalMenu from "../../../layout/Horizontalmenu.js";
import NonRevenueSummarylist from "./NonRevenueSummarylist.js";
// import Paymentcollected from "./Paymentcollected.js";
// import Filter from "../../../common/filter.js";
import Emptymsg from "../../../layout/message/Emptymsg.js";
import { ApiHelper, getUserData } from "../../../../utils/Helpers/index.js";
import { ApiRoutes } from "../../../../utils/Config/ApiRoutes.js";
import { messages } from "../../../../utils/Common/Messages.js";
import moment from "moment";

import { Layout, Row, Col, Skeleton } from "antd";

import "./NonRevenueSummary.less";
const { Content } = Layout;
//const title = "Sales Summary";
// const defaultCurrency = AppConfig.DEFAULTCURRENC;

let monthData = [];

let weekData = [];

let timeData = [];

const NonRevenueSummary = (props) => {
  console.log('NonRevenueSummary',props)
  const defaultCurrency = getUserData().currencySign;
  const monthConfig = {
    data: monthData,
    height: 180,
    autoFit: true,
    xField: "date",
    yField: "amount",

    yAxis: {
      label: {
        formatter: function formatter(v) {
          return v > 999
            ? defaultCurrency
                .concat(parseFloat(v).toFixed(2))
                .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                  return s !== "" && s !== null && s !== undefined
                    ? "".concat(s, ",")
                    : "";
                })
            : defaultCurrency + "" + parseFloat(v).toFixed(2);
        }
      }
    },

    point: {
      size: 4,

      shape: "diamond",

      style: {
        fill: "white",
        stroke: "#30649B",
        lineWidth: 2
      }
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 className="tooltipHeader" style={{ marginTop: 16 }}>
              {title}
            </h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year+index}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between"
                      }}
                    >
                      <span
                        className="g2-tooltip-name"
                        style={{
                          margiRight: 16,
                          textTransform: "capitalize",
                          paddingRight: "5px"
                        }}
                      >
                        {messages.amount[getUserData().language]}:{"  "}{" "}
                      </span>
                      <span className="g2-tooltip-list-item-value">
                        {defaultCurrency}
                        {parseFloat(value).toFixed(2)}{" "}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      }
    },
    state: {
      active: {
        style: {
          shadowColor: "yellow",
          shadowBlur: 4,
          stroke: "transparent",
          fill: "red"
        }
      }
    },
    meta: {
      month: { alias: "Month" },
      value: { alias: "Sales" }
    },
    theme: {
      geometries: {
        point: {
          diamond: {
            active: {
              style: {
                shadowColor: "#FCEBB9",
                shadowBlur: 2,
                stroke: "#F6BD16"
              }
            }
          }
        }
      }
    },
    interactions: [{ type: "marker-active" }]
  };

  const weekConfig = {
    data: weekData,
    height: 180,
    autoFit: true,
    xField: "date",
    yField: "amount",
    color: "#30649B",
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return v > 999
            ? defaultCurrency
                .concat(parseFloat(v).toFixed(2))
                .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                  return s !== "" && s !== null && s !== undefined
                    ? "".concat(s, ",")
                    : "";
                })
            : defaultCurrency + "" + parseFloat(v).toFixed(2);
        }
      }
    },
    meta: {
      type: { alias: "Category" },
      sales: { alias: "Sales" }
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 className="tooltipHeader" style={{ marginTop: 16 }}>
              {title}
            </h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year+index}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between"
                      }}
                    >
                      <span
                        className="g2-tooltip-name"
                        style={{
                          margiRight: 16,
                          textTransform: "capitalize",
                          paddingRight: "5px"
                        }}
                      >
                        {messages.amount[getUserData().language]}:{"  "}{" "}
                      </span>
                      <span className="g2-tooltip-list-item-value">
                        {defaultCurrency}
                        {parseFloat(value).toFixed(2)}{" "}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      }
    }
  };

  const timeConfig = {
    data: timeData,
    height: 180,
    autoFit: true,
    xField: "date",
    yField: "amount",
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return v > 999
            ? defaultCurrency
                .concat(parseFloat(v).toFixed(2))
                .replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                  return s !== "" && s !== null && s !== undefined
                    ? "".concat(s, ",")
                    : "";
                })
            : defaultCurrency + "" + parseFloat(v).toFixed(2);
        }
      }
    },

    point: {
      size: 4,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#30649B",
        lineWidth: 2
      }
    },
    tooltip: {
      customContent: (title, items) => {
        return (
          <>
            <h5 className="tooltipHeader" style={{ marginTop: 16 }}>
              {title}
            </h5>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item, index) => {
                const { value, color } = item;
                return (
                  <li
                    key={item.year+index}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{
                      marginBottom: 4,
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <span
                      className="g2-tooltip-marker"
                      style={{ backgroundColor: color }}
                    ></span>
                    <span
                      style={{
                        display: "inline-flex",
                        flex: 1,
                        justifyContent: "space-between"
                      }}
                    >
                      <span
                        className="g2-tooltip-name"
                        style={{
                          margiRight: 16,
                          textTransform: "capitalize",
                          paddingRight: "5px"
                        }}
                      >
                        {messages.amount[getUserData().language]}:{"  "}{" "}
                      </span>
                      <span className="g2-tooltip-list-item-value">
                        {defaultCurrency}
                        {parseFloat(value).toFixed(2)}{" "}
                      </span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      }
    },
    state: {
      active: {
        style: {
          shadowColor: "yellow",
          shadowBlur: 4,
          stroke: "transparent",
          fill: "red"
        }
      }
    },
    meta: {
      month: { alias: "Month" },
      value: { alias: "Sales" }
    },
    theme: {
      geometries: {
        point: {
          diamond: {
            active: {
              style: {
                shadowColor: "#FCEBB9",
                shadowBlur: 2,
                stroke: "#F6BD16"
              }
            }
          }
        }
      }
    },
    interactions: [{ type: "marker-active" }]
  };

  const { filterData, setFilterData } = props;
  const userData = getUserData();
  const lan = userData.language ?? "EN";
  const metaKey = userData.metaKey;
  // const query = new URLSearchParams(props.location.search);
  const download = userData.download ?? 0;
  const employeeId = userData ? userData.employeeId : 0;
  const currencySign = userData ? userData.currencySign : "$";
  const organizationId = userData
    ? userData.organizationTypeId === 1
      ? userData.parentOrganizationId
      : userData.organizationId
    : 0;
  const [loading, setLoading] = React.useState(true);
  const [paymentCollectedLoading, setPaymentCollectedLoading] =
    React.useState(true);
  const [noDatAvailable, setNoDatAvailable] = useState(false);
  const [summaryReport, setSummaryReport] = useState([]);
  const [taxSummaryReport, setTaxSummaryReport] = useState([]);
  const [voidSummaryReport, setVoidSummaryReport] = useState([]);
  const [paymentCollectData, setPaymentCollectData] = useState([]);
  const searchData = async (
    sDate = filterData.startDate,
    eDate = filterData.endDate,
    shiftId = filterData.shiftId,
    type = filterData.interval,
    sId = userData.organizationTypeId === 1 ? userData.organizationId : filterData.sId,
    employeeId = filterData.employeeId,
    limit = 0,
    summery = 1
  ) => {
    setFilterData({
      startDate: sDate,
      endDate: eDate,
      interval: type,
      shiftId: shiftId,
      sId: sId,
      employeeId:employeeId
    });

    setPaymentCollectedLoading(true);
    if (summery === 1) setLoading(true);
    else {
      paymentCollected(sDate, eDate, shiftId, type, sId,employeeId);
    }
    if (summery === 1) {
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GETALLNONREVRECORD.service,
        `${
          ApiRoutes.GETALLNONREVRECORD.url
        }?organizationId=${organizationId}&startDt=${sDate}&endDt=${eDate}&storeId=${sId}&employeeId=${employeeId}&shiftReport=${shiftId}&interval=${type}&gmtTime=${
          getUserData().time_gmt
        }`,
        ApiRoutes.GETALLNONREVRECORD.method,
        ApiRoutes.GETALLNONREVRECORD.authenticate,
        undefined
      );
      if (response.code === 200) {
        monthData = response.data.charts;
        monthConfig.data = monthData;
        weekData = response.data.week;
        weekConfig.data = weekData;
        timeData = response.data.time;
        timeConfig.data = timeData;
        if (
          response.data.noRecord === "true" ||
          response.data.noRecord === true
        ) {
          setNoDatAvailable(true);
          setLoading(false);
          setPaymentCollectedLoading(false);
        } else {
          setNoDatAvailable(false);
          paymentCollected(sDate, eDate, shiftId, type, sId,employeeId);
          setTimeout(() => setLoading(false), 500);
        }
      } else {
        setNoDatAvailable(true);
        setLoading(false);
        setPaymentCollectedLoading(false);
        // message.error({
        //   content: cMessage.reportHourError,
        //   duration: 2,
        // });
      }
    }
  };
  const paymentCollected = async (
    sDate = "",
    eDate = "",
    shiftId = "",
    type = "",
    sId = "",
    employeeId = "",
  ) => {
    setPaymentCollectedLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GETPAYMENTRECORDREVENUE.service,
      `${
        ApiRoutes.GETPAYMENTRECORDREVENUE.url
      }?organizationId=${organizationId}&startDt=${sDate}&endDt=${eDate}&storeId=${sId}&employeeId=${employeeId}&shiftReport=${shiftId}&interval=${type}&gmtTime=${
        getUserData().time_gmt
      }`,
      ApiRoutes.GETPAYMENTRECORDREVENUE.method,
      ApiRoutes.GETPAYMENTRECORDREVENUE.authenticate,
      undefined
    );
    if (response.code === 200) {
      setSummaryReport(response.data.summaryreportData);
      setTaxSummaryReport(response.data.taxReportData);
      setVoidSummaryReport(response.data.voidReportData);
      setPaymentCollectData(response.data.paymentData);
      setPaymentCollectedLoading(false);
    } else {
      // message.error({
      //   content: cMessage.reportHourError,
      //   duration: 2,
      // });
    }
  };
  useEffect(() => {
    searchData(filterData.startDate, filterData.endDate);
    // eslint-disable-next-line
  }, []); // ✅ OK in this example because we don't use *any* values from component scope
  return (
    <>
      {/*<Pagetitle title={title} />*/}
      {/* <HorizontalMenu /> */}
      {/* <Filter
        searchData={searchData}
        organizationId={organizationId}
        employeeId={employeeId}
        pageType="sale"
        filterData={filterData}
        setFilterData={setFilterData}
        userData={userData}
        lan={lan}
        currencySign={currencySign}
        download={download}
        metaKey={metaKey}
      /> */}
      {noDatAvailable === false ? (
        <>
          <Content className="content-body" style={{ minHeight: 200 }}>
            <Row>
              {filterData.startDate !== filterData.endDate ? (
                <>
                  <Col lg={24} xs={24}>
                    <div
                      className="bg-white"
                      style={{
                        margin: "0px 14px 0px 14px",
                        padding: 14,
                        minHeight: 200
                      }}
                    >
                      <Skeleton
                        paragraph={{ rows: 5 }}
                        loading={loading}
                        active
                      >
                        <h4 className="text-uppercase">{`${
                          messages[moment(filterData.startDate).format("MMM")][
                            lan
                          ]
                        } ${moment(filterData.startDate).format("DD, Y")}-${
                          messages[moment(filterData.endDate).format("MMM")][
                            lan
                          ]
                        } ${moment(filterData.endDate).format("DD, Y")}`}</h4>
                        <Line {...monthConfig} />
                      </Skeleton>
                    </div>
                  </Col>

                  <Col lg={8} xs={24}>
                    <div
                      className="bg-white"
                      style={{
                        margin: "14px 7px 14px 14px",
                        padding: 14,
                        minHeight: 200
                      }}
                    >
                      <Skeleton
                        paragraph={{ rows: 5 }}
                        loading={loading}
                        active
                      >
                        <h4 className="mb-1 text-uppercase">
                          {messages.dayOfWeek[lan]}
                        </h4>
                        <Column {...weekConfig} />
                      </Skeleton>
                    </div>
                  </Col>
                  <Col lg={16} xs={24}>
                    <div
                      className="bg-white"
                      style={{
                        margin: "14px 14px 14px 7px",
                        padding: 14,
                        minHeight: 200
                      }}
                    >
                      <Skeleton
                        paragraph={{ rows: 5 }}
                        loading={loading}
                        active
                      >
                        <h4 className="mb-1 text-uppercase">
                          {messages.timeOfDay[lan]}
                        </h4>
                        <Line {...timeConfig} />
                      </Skeleton>
                    </div>
                  </Col>
                </>
              ) : (
                <Col lg={24} xs={24}>
                  <div
                    className="bg-white"
                    style={{
                      margin: "0px 14px 14px 14px",
                      padding: 14,
                      minHeight: 200
                    }}
                  >
                    <Skeleton paragraph={{ rows: 5 }} loading={loading} active>
                      <h4 className="mb-1 text-uppercase">
                        {messages.timeOfDay[lan]}
                      </h4>
                      <Line {...timeConfig} />
                    </Skeleton>
                  </div>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <div
                  className="bg-white"
                  style={{ margin: "0px 14px 14px 14px", padding: 14 }}
                >
                
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <div
                  className="bg-white"
                  style={{ margin: "0px 14px 0px 14px", padding: 14 }}
                >
                 
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={24} xs={24}>
                <div
                  className="bg-white"
                  style={{ margin: "0px 14px 14px 14px", padding: 14 }}
                >
                  <Skeleton
                    paragraph={{ rows: 4 }}
                    loading={paymentCollectedLoading}
                    active
                  >
                    <h4 className="mb-1 text-uppercase">
                    NON Revenue {messages.summaryReport[lan]} 
                    </h4>
                    <NonRevenueSummarylist
                      interval={filterData.interval}
                      dataSetSummaryReport={summaryReport}
                      dataSetTaxSummaryReport={taxSummaryReport}
                      dataSetVoidSummaryReport={voidSummaryReport}
                      lan={lan}
                      currencySign={currencySign}
                    />
                  </Skeleton>
                </div>
              </Col>
            </Row>
          </Content>
       
        </>
      ) : (
        <Content className="content-body" style={{ minHeight: 200 }}>
          <Row>
            <Col lg={24} xs={24}>
              <div
                className="bg-white"
                style={{
                  margin: "0px 14px 0px 14px",
                  padding: 14,
                  minHeight: 200
                }}
              >
                <Skeleton paragraph={{ rows: 8 }} loading={loading} active>
                  <Emptymsg
                    emptytitle={messages.emptyTitle[lan]}
                    emptydescription={messages.emptyDescription[lan]}
                  />
                </Skeleton>
              </div>
            </Col>
          </Row>
        </Content>
      )}
    </>
  );
};
export default NonRevenueSummary;
